.wrap {
    width: 100%;
    float:left;
    overflow:hidden;
    height:"100%";
}

.resizable {
    width: 50%;
    height:100%;
    padding: 0;
    display: block;
    float:left;
    position:relative; 
}

.resizable1 {
    float:left;
    border-right-color: rgb(91, 79, 74);
    border-right-style: solid;
}

.resizable2 {
    float:right;
    overflow:hidden;
    background-color: white;
}

.resizable .inner {
  padding:30px;
  overflow:hidden;
  overflow-y:auto;
  position:absolute; 
  height:100%;
  top:0;
  left:0;
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.resizable1 .inner {
  margin-right:7px;
}
.resizable2 .inner {
  margin-left:7px;
}

.ui-resizable-e { 
    cursor: e-resize; 
    display:block!important;
    width: 14px; 
    right: -7px; 
    top: 0; 
    bottom: 0; 
    background: 'pink';
}
